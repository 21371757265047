<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          User id: {{ form.id }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12" md="6" class="text-center py-5 d-md-down-none">
              <CCardBody>
                <img src="img/boxes.png" alt="" style="width: 100%">
              </CCardBody>
            </CCol>
            <CCol xs="12" md="6">
              <CInput
                placeholder="Nombre Comercial"
                label="Nombre Comercial"
                v-model="form.comercialname"
                required
              >
                <template #prepend-content>
                  <font-awesome-icon icon="id-badge"/>
                </template>
              </CInput>
              <CInput
                placeholder="Dirección comercial"
                label="Dirección comercial"
                v-model="form.address"
                required
              >
                <template #prepend-content>
                  <font-awesome-icon icon="location-arrow"/>
                </template>
              </CInput>
              <CInput 
                placeholder="Nombre"
                label="Nombre" 
                v-model="form.name" 
                required
              >
                <template #prepend-content>
                  <font-awesome-icon icon="id-card"/>
                </template>
              </CInput>
              <CInput
                type="email"
                label="Correo"
                placeholder="Correo"
                autocomplete="email"
                v-model="form.email"
                required
              >
                <template #prepend-content
                  ><font-awesome-icon icon="at"
                /></template>
              </CInput>
              <CInput
                type="phone"
                placeholder="Teléfono"
                label="Teléfono"
                autocomplete="phone"
                v-model="form.phone"
              >
                <template #prepend-content
                  ><font-awesome-icon icon="phone"
                /></template>
              </CInput>
              <CInput
                type="password"
                placeholder="Contraseña"
                label="Contraseña"
                autocomplete="password"
                v-model="form.password"
              >
                <template #prepend-content
                  ><font-awesome-icon icon="key"
                /></template>
              </CInput>
              
              <CCol col="12" sm="12" lg="12" v-show="alert.show">
                <CAlert :color="alert.color" dismissible>
                  {{ alert.message }}
                </CAlert>
              </CCol>
              <CButton color="primary" @click="save">
                <CSpinner v-if="loading" variant="grow" size="sm" />
                Guardar
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";
import ProfileResource from "@/api/user";
const userResource = new Resource("user");
const profileResource = new ProfileResource();

export default {
  name: "User",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes("users");
    });
  },
  data() {
    return {
      usersOpened: null,
      loading: false,
      alert: {
        color: "danger",
        message: "",
        show: false,
      },
      form: {
        comercialname: "",
        address: "",
        name: "",
        email: "",
        phone: "",
      },
    };
  },
  computed: {
    fields() {
      return [
        { key: "key", label: this.username, _style: "width:150px" },
        { key: "value", label: "", _style: "width:150px;" },
      ];
    },
    userData() {
      const id = this.$route.params.id;
      const user = usersData.find((user, index) => index + 1 == id);
      const userDetails = user ? Object.entries(user) : [["id", "Not found"]];
      return userDetails.map(([key, value]) => {
        return { key, value };
      });
    },
    visibleData() {
      return this.userData.filter((param) => param.key !== "username");
    },
    username() {
      return this.userData.filter((param) => param.key === "username")[0].value;
    },
    vendorVisible() {
      return (
        (this.form.role == "Distribuidor" || this.form.role == "Mayorista") &&
        (this.$store.state.user.role == "Administrador" ||
          this.$store.state.user.role == "Financiero")
      );
    },
  },
  watch: {
    "form.role": async function (newValue, oldValue) {
      if (
        newValue != oldValue &&
        newValue != null &&
        newValue != "Administrador"
      ) {
        const index = this.role.indexOf(newValue);
        let parentRole = null;
        if (index >= 0) {
          parentRole = this.role[index - 1];
          await this.getParentsByRole(parentRole);
        }
        this.showParent = parentRole != null;
      }
    },
  },
  async mounted() {
    await this.loadClient();
  },
  methods: {
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/users" });
    },
    async loadClient() {
      await profileResource
        .profile()
        .then((response) => {
          this.form = response.data;
          this.loading = false;
          console.log(this.form);
        })
        .catch((error) => {
          //console.log(error);
          this.loading = false;
        });
    },
    save() {
      this.loading = true;
      userResource
        .update(this.form.id, this.form)
        .then((response) => {
          this.alert.show = true;
          this.alert.message = "Se guardó correctamente";
          this.alert.color = "success";
          this.loading = false;
        })
        .catch((error) => {
          this.alert.message = "Ocurrió un error al guardar";
          this.alert.color = "danger";
          this.loading = false;
        });
    },
  },
};
</script>
